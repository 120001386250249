import React from "react"
import PropTypes from "prop-types";
import { Container } from "@ui/wrapper";
import SectionTitle from "@ui/section-title";
import Anchor from "@ui/anchor";
import Image from "@ui/image";
import Heading from "@ui/heading";
import Text from "@ui/text";
import Button from "@ui/button";
import { SectionWrap, TranslateImgWrapper, TranslateImgBox, TranslateText, TranslateBottomBox } from "./style";
const TranslateImageBox = ({ data, ...rest }) => {
    return (
        <SectionWrap id={data.section}>
            <Container>
                <SectionTitle
                    title={data.section_title?.title}
                    subtitle={data.section_title?.subtitle}
                />
                {rest?.showRestComponents && <>
                    <TranslateImgWrapper layout={rest.layout}>
                        {data?.items.map((el, index) => (
                            <Anchor path={el.path} key={index} className="translateImgCol" title={el.title}>
                                <TranslateImgBox>
                                    <Image
                                        src={el.images[0]?.src}
                                        alt={el.images[0]?.alt || "Image"}
                                        title={el.images[0]?.alt || "Image"}
                                    />
                                </TranslateImgBox>
                                <TranslateText className="textWrapper">
                                    <Heading as="h4">{el.title}</Heading>
                                    {el.description && <Text as="p" className="desc">{el.description}</Text>}
                                </TranslateText>
                            </Anchor>
                        ))}
                    </TranslateImgWrapper>
                    <TranslateBottomBox>
                            {data?.headings?.[0] && (
                                <Heading as={data.headings[0]?.level}>
                                    {data.headings[0]?.content}
                                </Heading>
                            )}
                            {data?.buttons?.map(({ id, index, path, content, ...rest }) => (
                                <Button key={id} path={path} {...rest} title={content} className="customBtn bg-transparent p-0 h-auto border-0">
                                    {content}
                                </Button>
                            ))}
                        </TranslateBottomBox>
                </>
                }
            </Container>
        </SectionWrap>
    );
};
TranslateImageBox.propTypes = {
    items: PropTypes.object,
};
export default TranslateImageBox;